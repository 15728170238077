import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface MeasurementGraphProps {
  data: { time: string; [key: string]: number | string }[];
  field: string;
  measurement: string;
}

const MeasurementGraph: React.FC<MeasurementGraphProps> = ({ data, field, measurement }) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <h2>{measurement}</h2>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey={field} stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MeasurementGraph;
