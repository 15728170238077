import React, { createContext, useState, useContext } from 'react';

interface CarContextType {
  selectedCar: string;
  setSelectedCar: React.Dispatch<React.SetStateAction<string>>;
  selectedDrive: string;
  setSelectedDrive: React.Dispatch<React.SetStateAction<string>>;
}

export const CarContext = createContext<CarContextType | undefined>(undefined);

export const CarProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedCar, setSelectedCar] = useState<string>('');
  const [selectedDrive, setSelectedDrive] = useState<string>('');	

  return (
    <CarContext.Provider value={{ selectedCar, setSelectedCar, selectedDrive, setSelectedDrive }}>
      {children}
    </CarContext.Provider>
  );
};

export const useCarContext = () => {
  const context = useContext(CarContext);
  if (!context) {
    throw new Error('useCarContext must be used within a CarProvider');
  }
  return context;
};
