import React from "react";

export const Footer = () => {
  return (
    <div className="bg-dark text-light py-3">
      <footer className="container text-center">
        <p className="mb-0">
          <a
            href="https://www.htw-berlin.de/impressum/"
            className="text-light mx-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            Impressum
          </a>
          <a
            href="https://gitlab.rz.htw-berlin.de/huhn/automesssys"
            className="text-light mx-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitLab
          </a>
        </p>
      </footer>
    </div>
  );
};
