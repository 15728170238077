import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Cars from './components/Cars';
import GraphContainer from './components/Graph';
import { CarProvider } from './context/CarsContext';
import { Footer } from './components/Foother';
import './bootstrap';

function App() {
  return (
    <div className="bg-dark text-light min-vh-100 text-center">
      <h1>AutoMessSystem Dashboard</h1>
      <CarProvider>
        <Cars />
        <GraphContainer />
      </CarProvider>
      <Footer />
    </div>
  );
}

export default App;
