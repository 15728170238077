import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getBucketData as getBucketDataFunction } from "../api/influx";
import { useCarContext } from "../context/CarsContext";
import MeasurementGraph from "./MeasurementGraph";

interface MeasurementValue {
  time: string;
  field: string;
  value: number | string;
  tags: Record<string, any>;
}

type MeasurementsData = Record<string, MeasurementValue[]>;

const GraphContainer = () => {
  const [data, setData] = useState<MeasurementsData>({});
  const [loading, setLoading] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState<string | null>(null);

  const { selectedCar, selectedDrive } = useCarContext();

  useEffect(() => {
    if (!selectedCar || !selectedDrive) {
      setData({});
      return;
    }

    const fetchBucketData = async () => {
      setLoading(true);
      try {
        const result = await getBucketDataFunction(selectedCar, selectedDrive);
        if (result) {
          setData(result);
        } else {
          setData({});
        }
        if (Object.keys(result as object || {}).length > 0) {
          setSelectedMeasurement(Object.keys(result || {})[0]);
        }
      } catch (error) {
        console.error("Error fetching bucket data:", error);
        setData({});
      } finally {
        setLoading(false);
      }
    };

    fetchBucketData();
  }, [selectedCar, selectedDrive]);

  const measurementOptions = Object.keys(data).map((key) => ({
    value: key,
    label: key,
  }));

  const handleMeasurementChange = (selectedOption: { value: string; label: string } | null) => {
    setSelectedMeasurement(selectedOption ? selectedOption.value : null);
  };

  const convertToCsv = (data: MeasurementsData): string => {
    let csv = "time,field,value,tags\n";
    Object.keys(data).forEach((measurement) => {
      data[measurement].forEach((value) => {
        csv += `${value.time},${value.field},${value.value},${JSON.stringify(value.tags)}\n`;
      });
    });
    return csv;
  };

  const downloadCsv = () => {
    const csvData = convertToCsv(data);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', selectedCar + "_" + selectedDrive + '.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      {!selectedDrive ? (
        <p>Please select a ride to view the data.</p>
      ) : loading ? (
        <p>Loading data...</p>
      ) : Object.keys(data).length === 0 ? (
        <p>No data available for the selected car.</p>
      ) : (
        <div>
          <div className="d-flex justify-content-center mb-4">
            <Select
              className="form-select w-50"
              options={measurementOptions}
              onChange={handleMeasurementChange}
              value={measurementOptions.find((option) => option.value === selectedMeasurement)}
              placeholder="Select a measurement"
              isClearable
            />
          
          </div>

          <div className="d-flex justify-content-center mb-4">
            <button onClick={downloadCsv} className="btn btn-primary">
              Download CSV
            </button>
          </div>

          {selectedMeasurement && (
            <MeasurementGraph
              data={data[selectedMeasurement].map((value) => ({
                time: new Date(value.time).toLocaleString(),
                [value.field]: value.value,
              }))}
              field={data[selectedMeasurement][0].field}
              measurement={selectedMeasurement}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GraphContainer;
