import React, { useEffect, useState } from "react";
import { listBuckets, getFahrtTagValuesForAllMeasurements } from "../api/influx";
import { Bucket } from "../types";
import { useCarContext } from "../context/CarsContext";

const Cars = () => {
  const { setSelectedCar, setSelectedDrive } = useCarContext();
  const [buckets, setBuckets] = useState<Bucket[]>([]);
  const [fahrtValues, setFahrtValues] = useState<string[]>([]);
  const [selectedBucket, setSelectedBucket] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBuckets = async () => {
      try {
        const result = await listBuckets();
        setBuckets(result);
      } catch (error) {
        console.error("Error fetching buckets:", error);
      }
    };

    fetchBuckets();
  }, []);

  const handleBucketClick = async (name: string) => {
    setSelectedCar(name);
    setSelectedBucket(name);
    setSelectedDrive("");
    setLoading(true);

    try {
      const data = await getFahrtTagValuesForAllMeasurements(name);

      setLoading(false)

      const fahrtTagValues = new Set<string>();

      // Extract all unique "fahrt" tag values
      for (const measurement in data) {
        data[measurement].forEach((value) => {
          if (value.tags.fahrt) {
            fahrtTagValues.add(value.tags.fahrt);
          }
        });
      }

      setFahrtValues(Array.from(fahrtTagValues));
    } catch (error) {
      console.error("Error fetching fahrt tag values:", error);
    }
  };

  const handleFahrtChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedDrive(selectedValue);
  };

  return (
  

    <div className="container">
      
      <h4 className="text-center my-4">Auto auswählen</h4>
      <ul className="list-unstyled d-flex justify-content-center">
        {buckets.map((bucket) => (
          <li key={bucket.id} className="mx-2">
            <button
              className="btn btn-light"
              onClick={() => handleBucketClick(bucket.name)}
            >
              {bucket.name}
            </button>
          </li>
        ))}
      </ul>

      {loading && (
        <p>Loading data...</p>
      )}
      
      {selectedBucket && fahrtValues.length == 0 && !loading && (
        <div className="my-4">
          <h5 className="text-center">No ride for this car!</h5>
        </div>
      )}

      {selectedBucket && fahrtValues.length > 0 && !loading && (
        <div className="my-4">
          <h5 className="text-center">Select ride</h5>
          <div className="d-flex justify-content-center">
            <select
              className="form-select w-auto"
              onChange={handleFahrtChange}
            >
              <option value="" disabled selected>
                Please select a ride
              </option>
              {fahrtValues.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
    
  );
};

export default Cars;
