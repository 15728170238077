import { InfluxDB } from '@influxdata/influxdb-client';
import { BucketsAPI } from '@influxdata/influxdb-client-apis';
import React, { createContext, useContext, useState, useEffect } from 'react';

const influxUrl = 'https://amfa.f4.htw-berlin.de:8087'
const token = 'ycEfmTEknLa0v9g5DARIyVHq2O8eA7pDEdZcWTMR0bVfV8EjRX29nXaz5Wv3Oo6ew8ajtOhcSVfA2OHFapcfPg==';

const influxClient = new InfluxDB({ url: influxUrl, token });
const org = 'HTW';

const queryClient = influxClient.getQueryApi(org)
const bucketApi = new BucketsAPI(influxClient)

const InfluxDataContext = createContext<string | null>(null)


export const listBuckets = async (): Promise<any> => {
  try {
    const buckets = await bucketApi.getBuckets({ org });
    const filteredBuckets = buckets.buckets?.filter(bucket => !bucket.name.startsWith('_'));
    return filteredBuckets;
  } catch (error) {
    console.error('Error fetching buckets:', error);
    throw error;
  }
};

export const getFahrtTagValuesForAllMeasurements = async (bucket: string): Promise<MeasurementsData> => {
  const query = `
    import "influxdata/influxdb/schema"
    schema.measurements(bucket: "${bucket}")
  `;

  try {
    const measurementsResult: FluxRow[] = await queryClient.collectRows(query);
    const measurements: MeasurementsData = {};

    for (const row of measurementsResult) {
      const measurement = row._value;

      const valueQuery = `
        from(bucket: "${bucket}")
          |> range(start: 0)
          |> filter(fn: (r) => r._measurement == "${measurement}")
          |> filter(fn: (r) => exists r["fahrt"])
      `;

      const valuesResult: FluxRow[] = await queryClient.collectRows(valueQuery);

      measurements[measurement] = valuesResult.map(value => ({
        time: value._time || '',
        field: value._field || '',
        value: value._value,
        tags: value
      }));
    }

    return measurements;
  } catch (error) {
    console.error('Error fetching "fahrt" tag values:', error);
    throw error;
  }
};

interface MeasurementValue {
  time: string;
  field: string;
  value: number | string;
  tags: Record<string, any>;
}

interface BucketDataResult {
  measurements: MeasurementsData;
  csv: string;
}

type MeasurementsData = Record<string, MeasurementValue[]>;

interface FluxRow {
  _value: string;
  _time?: string;
  _field?: string;
  [key: string]: any;
}

export const getBucketData = async (bucket: string, fahrtTagValue: string): Promise<MeasurementsData | undefined> => {



  const query = `
    import "influxdata/influxdb/schema"
    schema.measurements(bucket: "${bucket}")
  `;

  try {
    const measurementsResult: FluxRow[] = await queryClient.collectRows(query);
    const measurements: MeasurementsData = {};

    let csv = 'measurement,time,field,value,tags\n';

    for (const row of measurementsResult) {
      const measurement = row._value;

      const valueQuery = `
        from(bucket: "${bucket}")
          |> range(start: 0)
          |> filter(fn: (r) => r._measurement == "${measurement}")
          |> filter(fn: (r) => r["fahrt"] == "${fahrtTagValue}")
      `;

      const valuesResult: FluxRow[] = await queryClient.collectRows(valueQuery);

      measurements[measurement] = valuesResult.map(value => ({
        time: value._time || '',
        field: value._field || '',
        value: value._value,
        tags: value
      }));
    }
    console.log(measurements);
    return measurements;
  } catch (error) {
    console.error('Error fetching data for fahrt tag value:', error);
    throw error;
  }
};
